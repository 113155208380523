import React, {useState} from 'react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Zoom, Pagination, Navigation} from 'swiper/modules';
import Close from "./icons/Close";
import Search from "./icons/Search";

function FilesSlider({images}) {
    const [heightBox, setBoxHeight] = useState('450px');
    const [height, setHeight] = useState('370px');
    const [width, setWidth] = useState('100%');
    const [borderRadius, setBorderRadius] = useState('0px');
    const [classes, setClasses] = useState('');
    const [openPhoto, setOpenPhoto] = useState(false);

    return (
        <div className="w-full relative">
            {
                images?.length > 0
                    ? (
                        <div className={classes}>
                            <div
                                style={{
                                    maxHeight: {
                                        xs: heightBox, sm: heightBox, lg: heightBox, md: heightBox,
                                    }, width: width,
                                    backgroundColor: 'white', padding: '10px', borderRadius: borderRadius,
                                }}>
                                {!openPhoto ?
                                    <div onClick={() => {
                                        setClasses('fixed top-0 left-0 w-full h-full bg-black/50 z-1100 flex justify-center items-center')
                                        setHeight('85vh')
                                        setWidth('90%')
                                        setBoxHeight('100vh')
                                        setOpenPhoto(true)
                                        setBorderRadius('15px')
                                    }}
                                         className="absolute top-2 right-2 z-10 p-3 bg-white rounded-full cursor-pointer shadow-widget">
                                        <Search classes="cursor-opinter"/>
                                    </div> :
                                    <div onClick={() => {
                                        setClasses('')
                                        setHeight('350px')
                                        setWidth('100%')
                                        setBoxHeight('450px')
                                        setBorderRadius('0px')
                                        setOpenPhoto(false)
                                    }}
                                         className="absolute top-3 right-3 z-10 p-3 bg-white rounded-full cursor-pointer shadow-widget">
                                        <Close/>
                                    </div>
                                }
                                <Swiper
                                    style={{
                                        '--swiper-navigation-color': 'var(--text-gray)',
                                        '--swiper-navigation-size': '30px',
                                        '--swiper-pagination-color': '#fff',
                                    }}
                                    zoom={true}
                                    navigation={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Zoom, Navigation, Pagination]}>
                                    {
                                        images.map((item, key) =>
                                            <SwiperSlide key={key}>
                                                <div className="swiper-zoom-container w-fit">
                                                    {isMediaFile(item) === 'image' ?
                                                        <img style={{height: height, width: '100%'}} loading={"lazy"}
                                                             src={item}
                                                             alt=""/> :
                                                        <video style={{height: height, width: '100%'}} controls
                                                               controlsList="nodownload">
                                                            <source src={item}/>
                                                        </video>
                                                    }
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                            </div>
                        </div>
                    )
                    : (
                        <div>
                        </div>

                    )
            }
        </div>
    );
}

function isMediaFile(filePath) {
    let type = '';
    // Список разрешённых расширений для изображений и видео
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'ogg', 'flv', 'wmv'];

    // Извлекаем расширение файла
    const extension = filePath.split('.').pop().toLowerCase();

    // Проверяем, находится ли расширение в списке разрешённых
    if (imageExtensions.includes(extension)) {
        type = 'image';
    } else if (videoExtensions.includes(extension)) {
        type = 'video';
    }

    return type;
}

export default FilesSlider;


