import React, {useEffect, useState} from 'react';
import Widget from "./Widget";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';

function CalculatePreview({Directions}) {
    const [refresh, setRefresh] = useState(false)
    const [ListDirection, setListDirections] = useState([])
    const [listCityStart, setListCityStart] = useState([])
    const [listCityFinish, setListCityFinish] = useState([])
    const [listTempMode, setListTempMode] = useState([])
    const [tempMode, setTempMode] = useState('ТемпературныйРежим_БезРежима')
    const textCityStart = 'Откуда'
    const textCityEnd = 'Куда'
    const listTempModeVariant = {
        ТемпературныйРежим_4: '+4',
        ТемпературныйРежим_18: '-18',
        ТемпературныйРежим_БезРежима: 'Без режима'
    }
    const [cityStart, setCityStart] = useState(null)
    const [cityFinish, setCityFinish] = useState(null)

    function getListDirections() {
        setListDirections(Directions)
    }

    function filterCityList(type = 'setAll') {
        const tempModeList = []
        let currentCityStart = cityStart

        if (type === 'setEnd' || type === 'setAll') {
            const cityStartList = []

            ListDirection?.forEach((city) => {
                if (city.guidFinish === cityFinish?.guidFinish || cityStart === null) {
                    const findValItemEnd = cityStartList.find((itemCityEnd) => itemCityEnd.guidStart === city.guidStart)
                    if (findValItemEnd === undefined) {
                        cityStartList.push(city)
                    }
                    const tempMode = tempModeList.find((itemTempmode) => itemTempmode.tempmode === city.tempmode)
                    if (tempMode === undefined) {
                        tempModeList.push(city)
                    }
                }
            })

            currentCityStart = cityStartList[0]
            setListCityStart(cityStartList)
            if (type === 'setAll') {
                setCityStart(cityStartList[0])
            }
        }
        if (type === 'setStart' || type === 'setAll') {
            const cityEndList = []
            ListDirection?.forEach((city) => {
                if (city.guidStart === currentCityStart?.guidStart) {
                    const findValItemEnd = cityEndList.find(
                        (itemCityEnd) => itemCityEnd.guidFinish === city.guidFinish)
                    if (findValItemEnd === undefined) {
                        cityEndList.push(city)
                    }
                    const tempMode = tempModeList.find(
                        (itemTempmode) => itemTempmode.tempmode === city.tempmode)
                    if (tempMode === undefined) {
                        tempModeList.push(city)
                    }
                }
            })
            setListCityFinish(cityEndList)
            setCityFinish(cityEndList[0])
        }
        setListTempMode(tempModeList)
    }

    useEffect(() => {
        getListDirections()
    }, [])

    useEffect(() => {
        if (ListDirection.length > 0) {
            filterCityList('setAll')
        }
    }, [ListDirection])

    useEffect(() => {
        if (refresh) {
            filterCityList('setStart')
            setRefresh(false)
        }
    }, [cityStart])

    useEffect(() => {
        if (refresh) {
            filterCityList('setEnd')
            setRefresh(false)
        }
    }, [cityFinish])


    const TempModeChange = (event) => {
        setTempMode(event.target.value);
    };

    const StyledAutocomplete = styled(Autocomplete)(({}) => ({
        '& .MuiInputBase-root': {
            '& .MuiInputBase-input': {
                color: 'white'
            }
        },
        '& .MuiInputLabel-root': {
            color: 'white',
            '&.Mui-focused': {
                color: 'white'
            }
        },
        '& .MuiIconButton-root': {
            color: 'white'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#68b3ff',
                borderWidth: 2,
            },
        },
    }));

    const StyledSelect = styled(Select)(({}) => ({
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #ccc',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68b3ff',
            borderWidth: '2px',
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },

        '&.Mui-focused': {
            color: 'white',
        },
    }));

    const StyledInputLabel = styled(InputLabel)(({}) => ({
        color: 'white',
        '&.Mui-focused': {
            color: '#ffffff'
        },
    }));

    console.log(cityStart)
    return (
        <Widget titleWidget="Рассчитать доставку">
            <form action="" method="GET" id="search_form" className="w-full relative z-40">
                    <span>
                <svg width="13" height="8" className="md:hidden block" viewBox="0 0 13 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.389175 1.02368C0.0343374 1.3785 0.0343374 1.95383 0.389175 2.30864L4.83425
                     6.74936C5.54405 7.45844 6.69416 7.45816 7.4036 6.74881L11.847 2.30537C12.2019 1.95056 12.2019
                      1.37523 11.847 1.0204C11.4921 0.665563 10.9168 0.665563 10.562 1.0204L6.75895 4.82348C6.40414
                      5.17838 5.82881 5.17829 5.474 4.82348L1.67413 1.02368C1.31931 0.668843 0.744004 0.668843
                      0.389175 1.02368Z"
                          fill="#158BF6"/>
                </svg>
            </span>
                <div className="md:flex flex-col w-full hidden box-hidden-calculate">
                    <div className="flex md:flex-row flex-col w-full items-center 2xl:mb-6 mb-3 justify-between">
                        <div className="mb-2 md:w-1/2 w-full md:pr-2 autocomplite-box">
                            <StyledAutocomplete
                                name="startCity"
                                className="text_style_inp 2xl:mr-4 text-sm 2xl:text-xl border-none rounded-md outline-0
                                text-black mr-2 w-full"
                                value={cityStart}
                                onChange={(event, newValue) => {
                                    setRefresh(true)
                                    setCityStart(newValue)
                                }}
                                size="medium"
                                disablePortal
                                options={listCityStart}
                                getOptionLabel={(option) => option.CityStart}
                                sx={{
                                    width: "100%"
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    label={textCityStart}/>}
                            />
                        </div>
                        <div className="mb-2 md:w-1/2 w-full autocomplite-box">
                            <StyledAutocomplete
                                className="text_style_inp 2xl:text-lg 2xl:mr-4 text-sm border-none rounded-md  outline-0
                              text-white mr-2 w-full"
                                value={cityFinish}
                                name="finishCity"
                                size="medium"
                                onChange={(event, newValue) => {
                                    setRefresh(true)
                                    setCityFinish(newValue)
                                }}
                                disablePortal
                                options={listCityFinish}
                                getOptionLabel={(option) => option.СityFinish}
                                sx={{
                                    width: "100%"
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    label={textCityEnd}/>}
                            />
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col">
                        <div className=" md:w-1/2 w-full min-w-[150px] h-full md:mr-2 mr-0 autocomplite-box">
                            <FormControl fullWidth>
                                <StyledInputLabel id="demo-simple-select-label" className="text-white">
                                    Температурный режим</StyledInputLabel>
                                <StyledSelect
                                    size="medium"
                                    sx={{
                                        width: "100%"
                                    }}
                                    labelId="demo-simple-select-label"
                                    label="Температурный режим"
                                    value={tempMode}
                                    onChange={TempModeChange}>
                                    {
                                        listTempMode.map((item, index) => (
                                            <MenuItem value={item.tempmode} key={index} onClick={() => {
                                                setTempMode(item.tempmode)
                                            }}>{listTempModeVariant[item.tempmode]}</MenuItem>
                                        ))
                                    }
                                </StyledSelect>
                            </FormControl>
                        </div>
                        <div className="w-1/2 h-auto">
                            <a href={"/calculator-order/" + "?cityStart=" + (cityStart?.guidStart + "&cityFinish=" + cityStart?.guidFinish + "&tempmode=" + tempMode)}
                               target="_blank" rel="noopener noreferrer"
                               className="py-2 px-10 2xl:py-3 2xl:px-14 font-normal 2xl:text-xl text-base text-white
                                mb-2 rounded-md bg-primary text-center md:w-auto w-full flex h-full justify-center items-center">
                                Рассчитать
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </Widget>
    );
}

export default CalculatePreview;
