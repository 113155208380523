import React from 'react';
import ReactDOM from 'react-dom/client';
import CalculatePreview from "./components/CalculatePreview";
import FilesSlider from "./components/FilesSlider";
import './css/index.css';
import Calculate from "./components/calculate/Calculate.jsx";

// React 18 рендеринг

let calculate = document.getElementById('calculate');
if (calculate) {
    let Directions = JSON.parse(calculate.dataset.directions)
    ReactDOM.createRoot(calculate).render(
        <React.StrictMode>
            <CalculatePreview Directions={Directions}/>
        </React.StrictMode>
    );
}

let slider = document.getElementById('sliderFilial');
if (slider) {
    let files = JSON.parse(slider.dataset.src);
    ReactDOM.createRoot(slider).render(
        <React.StrictMode>
            <FilesSlider images={files}/>
        </React.StrictMode>
    );
}

let calculateAppend = document.getElementById('calculateAppend');
if (calculateAppend) {

    let Directions = JSON.parse(calculateAppend.dataset.directions);

    const urlParams = new URLSearchParams(window.location.search);
    const cityStartParam = urlParams.get('cityStart') || '';
    const cityFinishParam = urlParams.get('cityFinish') || '';
    const tempmodeParam = urlParams.get('tempmode') || '';

    ReactDOM.createRoot(calculateAppend).render(
        <React.StrictMode>
            <Calculate cityStartParam={cityStartParam} cityFinishParam={cityFinishParam} tempmodeParam={tempmodeParam}
                       Directions={Directions}/>
        </React.StrictMode>
    );
}