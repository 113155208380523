import React from 'react';
import TextField from '@mui/material/TextField';
import axios from "axios";
function UserSteps({
                       showUserData,
                       showResult,
                       messages,
                       setMessages,
                       dataSendToJson,
                       setShowUserData,
                       showDelivery,
                       setShowDelivery,
                       activeTariff,
                       setShowResult,
                       setTextResult
                   }) {

    function validateStepUsers() {
        const _messages = []
        const regMail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

        if (dataSendToJson?.NameUser == '') {
            _messages.push({key: 'NameUser', desc: 'Заполните поле', type: 'warning'})
        }
        if (dataSendToJson?.LastName == '') {
            _messages.push({key: 'LastName', desc: 'Заполните поле', type: 'warning'})
        }
        if (dataSendToJson.Surname == '') {
            _messages.push({key: 'Surname', desc: 'Заполните поле', type: 'warning'})
        }
        if (dataSendToJson.INN == '') {
            _messages.push({key: 'INN', desc: 'Заполните поле', type: 'warning'})
        }

        if (dataSendToJson.Phone == '') {
            _messages.push({key: 'Phone', desc: 'Заполните поле', type: 'warning'})
        }

        if (regMail.test(dataSendToJson.Email) == false) {
            _messages.push({key: 'email', desc: 'Email заполнен неверно', type: 'warning'})
        }

        setMessages(_messages)
        if (_messages.length === 0) {
            sendAppendApplication()
        }
    }

    function sendAppendApplication() {

        let arData = {
            activeTariff: activeTariff,
            dataSendToJson: dataSendToJson
        }

        axios.post(ajaxurl, new URLSearchParams({
            action: 'addApplication',
            arData: JSON.stringify(arData)
        }).toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Формат стандартный для WP
            },
        }).then((res) => {
            if (res.data !== 'error') {
                setShowResult(true)
                setTextResult(res.data)
            }
        })
    }


    return (
        showUserData && !showResult && !showDelivery ?
            <>
                <div className="w-full md:px-8">
                    <div className="mb-5">
                        <p className="mb-7 text-titleBox text-xl font-medium">Ваши данные</p>
                        <div className="flex md:flex-row flex-col justify-between">
                            <div className="md:w-[32%] w-full">
                                <TextField label="Имя" variant="outlined" type="text" className="w-full"
                                           size="small" sx={{mb: 2}} required
                                           error={messages.find(item => item.key === 'NameUser') !== undefined}
                                           helperText={messages.find(item => item.key === 'NameUser') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                           onChange={(event) => {
                                               dataSendToJson.NameUser = event.target.value
                                           }}/>
                            </div>
                            <div className="md:w-[32%] w-full">
                                <TextField label="Фамилия" variant="outlined" type="text" className="w-full"
                                           size="small" sx={{mb: 2}}
                                           error={messages.find(item => item.key === 'LastName') !== undefined}
                                           helperText={messages.find(item => item.key === 'LastName') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                           required onChange={(event) => {
                                    dataSendToJson.LastName = event.target.value
                                }}/>
                            </div>
                            <div className="md:w-[32%] w-full">
                                <TextField label="Отчество" size="small" type="text" className="w-full"
                                           variant="outlined"
                                           error={messages.find(item => item.key === 'Surname') !== undefined}
                                           helperText={messages.find(item => item.key === 'Surname') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                           sx={{mb: 2}}
                                           required onChange={(event) => {
                                    dataSendToJson.Surname = event.target.value
                                }}/>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col justify-between">
                            <div className="md:w-[32%] w-full">
                                <TextField label="Телефон" size="small" type="phone" className="w-full"
                                           variant="outlined"
                                           sx={{mb: 2}}
                                           required
                                           error={messages.find(item => item.key === 'Phone') !== undefined}
                                           helperText={messages.find(item => item.key === 'Phone') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                           onChange={(event) => {
                                               dataSendToJson.Phone = event.target.value
                                           }}/>
                            </div>
                            <div className="md:w-[32%] w-full">
                                <TextField label="Email" size="small" type="Email" className="w-full"
                                           variant="outlined"
                                           sx={{mb: 2}}
                                           error={messages.find(item => item.key === 'Email') !== undefined}
                                           helperText={messages.find(item => item.key === 'Email') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                           required
                                           onChange={(event) => {
                                               dataSendToJson.Email = event.target.value
                                           }}/>
                            </div>
                            <div className="md:w-[32%] w-full">
                                <TextField label="ИНН" size="small" type="text" className="w-full"
                                           variant="outlined"
                                           error={messages.find(item => item.key === 'INN') !== undefined}
                                           helperText={messages.find(item => item.key === 'INN') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                           required
                                           sx={{mb: 2}}
                                           onChange={(event) => {
                                               dataSendToJson.INN = event.target.value
                                           }}/>
                            </div>
                        </div>
                        <div className="w-full">
                            <TextField label="Имя компании" size="small" type="text" className="w-full"
                                       variant="outlined"
                                       sx={{mb: 2}}
                                       onChange={(event) => {
                                           dataSendToJson.CompanyName = event.target.value
                                       }}/>
                        </div>
                    </div>
                    <p className="text-xs font-normal">* Указание ваших личных данных необходимо для
                        оформления предзаявки и дальнейшей связи с вами. <br></br>
                        Более детальную информацию вам предоставит наш Менеджер позднее.
                    </p>
                    <div className="mt-5 w-full flex justify-end">
                        <div className="bg-primary py-2 px-5 rounded-sm text-white mr-5 cursor-pointer" onClick={() => {
                            setShowUserData(false)
                            setShowDelivery(true)
                        }}>Назад
                        </div>
                        <div className="bg-primary py-2 px-5 rounded-sm text-white cursor-pointer"  onClick={() => {
                            validateStepUsers()
                        }}> Оформить предзаявку
                        </div>
                    </div>
                </div>
            </> : false
    );
}

export default UserSteps;