import React, {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import axios from "axios";

function CalculateFieldSteps({
                                 cityStart,
                                 setCityStart,
                                 setRefresh,
                                 setShowDelivery,
                                 setListCalculateResult,
                                 setCityFinish,
                                 setMessages,
                                 dataSendToJson,
                                 cityFinish,
                                 listCityStart,
                                 listCityFinish,
                                 listTempMode,
                                 listTempModeVariant,
                                 messages,
                                 tempmodeParam
                             }) {

    const minDate = dayjs().add(2, 'day');
    const textCityStart = 'Откуда'
    const textCityEnd = 'Куда'
    const [selectedDate, setSelectedDate] = useState(null);
    const [ExpeditionFinish, setExpeditionFinish] = useState(false)
    const [insurance, setInsurance] = useState(false)
    const [tempMode, setTempMode] = useState(tempmodeParam || 'ТемпературныйРежим_БезРежима')
    const [forwardingShipment, setForwardingShipment] = useState(false)
    const SetForwardingShipmentCheck = (event) => {
        setForwardingShipment(event.target.checked)
        dataSendToJson.forwardingShipment = event.target.checked
    }

    const setExpeditionFinishCheck = (event) => {
        setExpeditionFinish(event.target.checked)
        dataSendToJson.forwardingDestination = event.target.checked
    }

    const setInsuranceCheck = (event) => {
        setInsurance(event.target.checked)
        dataSendToJson.insurance = event.target.checked
    }

    function validateStepOne() {
        const _messages = []

        if (dataSendToJson.weightMain == 0) {
            _messages.push({key: 'weightMain', desc: 'Заполните поле', type: 'warning'})
        }

        if (dataSendToJson.bulkMain == 0) {
            _messages.push({key: 'bulkMain', desc: 'Заполните поле', type: 'warning'})
        }
        if (dataSendToJson.goodPrice == 0) {
            _messages.push({key: 'goodPrice', desc: 'Заполните поле', type: 'warning'})
        }

        if (dataSendToJson.pallet == 0) {
            _messages.push({key: 'pallet', desc: 'Заполните поле', type: 'warning'})
        }

        setMessages(_messages)
        if (_messages.length === 0) {
            sendAjaxCalculate()
        }
    }

    function sendAjaxCalculate() {

        dataSendToJson.finishCity = cityFinish.guidFinish
        dataSendToJson.finishCityName = cityFinish.СityFinish
        dataSendToJson.startCityName = cityStart.CityStart
        dataSendToJson.startCity = cityStart.guidStart
        dataSendToJson.tempmode = tempMode

        axios.post(ajaxurl, new URLSearchParams({
            action: 'calculate_shipping',
            dataSendToJson: JSON.stringify(dataSendToJson)
        }).toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Формат стандартный для WP
            },
        }).then((res) => {
            if (res.data !== 'error') {
                setListCalculateResult(res.data)
                setShowDelivery(true)
            }
        })
    }

    return (
        <div className="w-full md:px-8">
            <div className="flex md:flex-row flex-col items-start mb-2">
                <div className="mb-3 md:w-1/3 w-full flex flex-col">
                    <div className="w-full mb-3">
                        <p className="mb-5 text-titleBox text-lg font-medium">Направление</p>
                        <div className="flex flex-col justify-between w-full">
                            <div className="mb-3 w-full relative">
                                <Autocomplete
                                    size="small"
                                    value={cityStart}
                                    onChange={(event, newValue) => {
                                        setRefresh(true)
                                        setCityStart(newValue)
                                    }}
                                    disablePortal
                                    options={listCityStart}
                                    getOptionLabel={(option) => option.CityStart}
                                    sx={{
                                        width: "100%"
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={textCityStart}/>}
                                />
                            </div>
                            <div className="mb-3 w-full relative">
                                <Autocomplete
                                    size="small"
                                    value={cityFinish}
                                    onChange={(event, newValue) => {
                                        setRefresh(true)
                                        setCityFinish(newValue)
                                    }}
                                    disablePortal
                                    options={listCityFinish}
                                    getOptionLabel={(option) => option.СityFinish}
                                    sx={{
                                        width: "100%"
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={textCityEnd}/>}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full mb-3">
                        <p className="mb-4 text-titleBox text-lg font-medium">Температурный
                            режим</p>
                        <div className="flex flex-row flex-wrap">
                            {
                                listTempMode.map((item, index) => (
                                    <div
                                        className={'px-6 py-2 rounded mb-3 text-center mr-3 text-sm cursor-pointer font-normal'
                                            + (tempMode === item.tempmode ?
                                                ' bg-primary text-white ' : ' border border-borderGray text-titleBox')}
                                        key={index}
                                        onClick={() => {
                                            setTempMode(item.tempmode)
                                        }}>
                                        {listTempModeVariant[item.tempmode]}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="mb-3 md:w-1/3 w-full md:pl-7">
                    <div className="w-full flex flex-col mb-3">
                        <p className="mb-5 text-titleBox text-lg font-medium">Экспедирование</p>
                        <div className="w-full mb-3">
                            <FormControlLabel
                                className="text-titleBox"
                                value="ExpeditionFinish"
                                control={(
                                    <Switch
                                        checked={forwardingShipment}
                                        onClick={SetForwardingShipmentCheck}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                )}
                                label="В пункте отправления"
                            />
                        </div>
                        <div className="w-full mb-3">
                            <FormControlLabel
                                className="text-titleBox"
                                value="forwardingShipment"
                                control={(
                                    <Switch
                                        checked={ExpeditionFinish}
                                        onClick={setExpeditionFinishCheck}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                )}
                                label="В пункте назначения"
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="mb-4 text-titleBox text-lg font-medium">Дата забора груза</p>
                        <div className="flex flex-row flex-wrap">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Выберите дату"
                                    value={selectedDate}
                                    className="datePicker"
                                    style={{fontSize: '12px', padding: '8px'}}
                                    size="small"
                                    minDate={minDate}
                                    format="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        dataSendToJson.dateClient = newValue.format('DD.MM.YYYY')
                                        setSelectedDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
                <div className="mb-3 md:w-1/3 w-full md:pl-5">
                    <div className="w-full mb-3">
                        <p className="mb-5 text-titleBox text-lg font-medium">Параметры
                            груза</p>
                        <div className="flex flex-row flex-wrap w-full">
                            <TextField label="Вес груза,  кг" variant="outlined" type="number"
                                       size="small" sx={{mb: 2, width: '100%', marginRight: 1}} required
                                       className="w-fit mr-1"
                                       error={messages.find(item => item.key === 'weightMain') !== undefined}
                                       helperText={messages.find(item => item.key === 'weightMain') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                       onChange={(event) => {
                                           dataSendToJson.weightMain = event.target.value
                                       }}/>
                            <TextField label="Объем груза, м3"
                                       className="w-fit mr-1"
                                       variant="outlined" type="number"
                                       error={messages.find(item => item.key === 'bulkMain') !== undefined}
                                       size="small" sx={{mb: 2, width: '100%', marginRight: 1}} required
                                       helperText={messages.find(item => item.key === 'bulkMain') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                       onChange={(event) => {
                                           dataSendToJson.bulkMain = event.target.value
                                       }}/>
                            <TextField label="Стоимость груза, руб" type="number"
                                       variant="outlined"
                                       className="w-fit mr-1"
                                       error={messages.find(item => item.key === 'goodPrice') !== undefined}
                                       size="small" sx={{mb: 2, width: '100%', marginRight: 1}} required
                                       helperText={messages.find(item => item.key === 'goodPrice') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                       onChange={(event) => {
                                           dataSendToJson.goodPrice = event.target.value
                                       }}/>

                            <TextField label="Паллет, штук" type="number"
                                       className="w-fit mr-1"
                                       variant="outlined"
                                       helperText={messages.find(item => item.key === 'pallet') !== undefined ? "* Поле обязательно для заполнения" : ''}
                                       error={messages.find(item => item.key === 'pallet') !== undefined}
                                       size="small" sx={{mb: 2, width: '100%', marginRight: 1}} required
                                       onChange={(event) => {
                                           dataSendToJson.pallet = event.target.value
                                       }}/>
                        </div>
                        <div className="w-full mb-3">
                            <FormControlLabel
                                value="insurance"
                                className="text-titleBox"
                                control={(
                                    <Switch
                                        checked={insurance}
                                        onChange={setInsuranceCheck}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                )}
                                label="Дополнительное страхование (0,11%)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-5 w-full flex md:justify-end justify-center">
                <div onClick={validateStepOne}
                     className="bg-primary py-2 px-7 rounded-sm text-white">Рассчитать
                </div>
            </div>
        </div>
    );
}

export default CalculateFieldSteps;