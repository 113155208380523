import React, {useState} from 'react';

function CalculateResult({
                             resultItem,
                             key,
                             listTempModeVariant,
                             dataSendToJson,
                             setActiveTariff,
                             activeTariff,
                             setShowUserData,
                             showResult,
                         }) {

    const [hiddenBox, setHiddenBox] = useState(true)
    let selectTariff = (resultItem?.TypeTransportation?.guid === activeTariff?.TypeTransportation?.guid);

    return (
        <div key={key}
             className={"mb-3 text-titleBox flex md:flex-row flex-col shadow-lg rounded-3xl md:w-fit md:min-w-[450px] min-w-[250px] w-full md:mr-4" +
                 " mr-0 h-fit " + (selectTariff ? " border-2 border-primary/30 " : "")}>
            <div className="flex flex-col flex-wrap px-6 py-4 md:w-1/2 w-full">
                <div className="w-full flex flex-col justify-between relative mb-3 md:mb-0 h-full">
                    <div>
                        <div className="font-semibold md:text-base lg:text-base text-sm xl:text-lg 2xl:text-lg mb-3
                     text-center border-b border-gray-200 pb-2 flex flex-row">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="mr-2"
                                 width="24" height="24">
                                <path stroke="#1AB248" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6"></path>
                                <path stroke="#1AB248" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M17.657 16.657 13.414 20.9a2 2 0 0 1-2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0"></path>
                            </svg>
                            {resultItem.TypeTransportation.name}
                        </div>
                        <div className="flex flex-row justify-between w-full items-center md:mb-0 mb-3">
                            <p className="md:text-sm lg:text-sm text-sm xl:text-base 2xl:text-base md:mr-0 mr-3 w-1/2">
                                В пути (дней)
                                <div className="font-semibold mt-2">{resultItem.Days}</div>
                            </p>
                            <p className="md:text-sm lg:text-sm text-sm xl:text-base 2xl:text-base  md:mr-0 mr-3  w-1/2">
                                Итого (руб.)
                                <div
                                    className="font-bold text-colorRed mt-2">{parseInt(resultItem.Price + resultItem.DeclaredValue +
                                    resultItem.WarehouseEntrance + resultItem.DeliveryShipmentPrice
                                    + resultItem.DeliveryDestinationPrice + (resultItem.InsurancePrice * 10))} </div>
                            </p>
                        </div>
                    </div>
                    {
                        !showResult ?
                            <div
                                className="text-center rounded-sm text-sm text-white bg-primary mt-1.5 py-1.5 px-3 mb-0 cursor-pointer"
                                onClick={() => {
                                    setShowUserData(true)
                                    setActiveTariff(resultItem)
                                }}>
                                Выбрать
                            </div>
                            : false
                    }
                </div>
                {
                    window.innerWidth <= 768 ?
                        <div className="p-2 cursor-pointer flex justify-center" onClick={() => {
                            setHiddenBox(!hiddenBox)
                        }}>
                            <svg width="18" height="12" viewBox="0 0 13 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.607681 1.02368C0.252843 1.3785 0.252843 1.95383 0.607681 2.30864L5.05275 6.74936C5.76256
                                7.45844 6.91267 7.45816 7.62211 6.74881L12.0655 2.30537C12.4204 1.95056 12.4204 1.37523 12.0655 1.0204C11.7107
                                 0.665563 11.1353 0.665563 10.7805 1.0204L6.97745 4.82348C6.62264 5.17838 6.04731 5.17829 5.6925
                                 4.82348L1.89264 1.02368C1.53781 0.668843 0.96251 0.668843 0.607681 1.02368Z"
                                      fill="#158BF6"></path>
                            </svg>
                        </div>
                        : false
                }
            </div>
            <div className={(hiddenBox && window.innerWidth <= 768) ? "hidden" : " md:w-1/2 w-full"}>
                <div className="flex flex-col justify-between text-titleBox px-6 py-3">
                    <div className="w-full">
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Транспортировка
                            : <span className="font-semibold text-titleBox">{resultItem.Price}</span></p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Экспедирование
                            в П.О.
                            : <span className="font-semibold text-titleBox">{resultItem.DeliveryShipmentPrice}</span>
                        </p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Экспедирование
                            в П.Н.
                            : <span className="font-semibold text-titleBox">{resultItem.DeliveryDestinationPrice}</span>
                        </p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> t
                            °С - режим
                            : <span
                                className="font-semibold text-titleBox">{listTempModeVariant[dataSendToJson.tempmode]}</span>
                        </p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Доп.
                            страхование
                            (0.11%) <span className="font-semibold text-titleBox">{resultItem.InsurancePrice}</span></p>
                    </div>
                    <div className="w-full">
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> В
                            пути (дней) :
                            <span className="font-semibold text-titleBox">{resultItem.Days}</span></p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark">Отправка
                            :
                            <span className="font-semibold text-titleBox">{resultItem.SendingDays}</span></p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Въезд
                            на склад в ПО :
                            <span className="font-semibold text-titleBox">{resultItem.WarehouseEntrance} руб.</span></p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Объявленная
                            стоимость :
                            <span className="font-semibold text-titleBox">{resultItem.DeclaredValue} руб.</span></p>
                        <p className="flex justify-between  mb-2 md:text-sm lg:text-sm text-base xl:text-base 2xl:text-base text-titleGrayDark"> Итого
                            :
                            <span
                                className="font-bold text-colorRed ">{parseInt(resultItem.Price + resultItem.DeclaredValue +
                                resultItem.WarehouseEntrance + resultItem.DeliveryShipmentPrice
                                + resultItem.DeliveryDestinationPrice + resultItem.InsurancePrice)} руб.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculateResult;
