import React from 'react';

function Search({classes}) {
    return (
        <svg width="19" height="19" className={classes} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9129 13.8834L18 18M16.1111 8.55556C16.1111 12.7284 12.7284 16.1111 8.55556 16.1111C4.38274
                16.1111 1 12.7284 1 8.55556C1 4.38274 4.38274 1 8.55556 1C12.7284 1 16.1111 4.38274 16.1111 8.55556Z"
                stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Search;
