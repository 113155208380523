import React from 'react';

function Widget({
                    children,
                    classes = '',
                    paddingClass = '2xl:p-8 md:p-6 p-5',
                    titleWidget = '',
                    titleDescription = '',
                    main = false,
                }) {
    return (
        <div className={classes + " flex w-full text-white flex-col rounded-3xl " +
            "bg-white/25 backdrop-blur-md " + paddingClass}>
            <div className="2xl:text-3xl lg:text-2xl text-xl mb-7 font-normal text-left"
                 dangerouslySetInnerHTML={{__html: titleWidget}}></div>
            {
                titleDescription !== '' ?
                    <p className="2xl:text-3xl text-xl mb-7 font-normal text-left"
                       dangerouslySetInnerHTML={{__html: titleDescription}}></p>
                    : false
            }
            <div>
                {children}
            </div>
        </div>
    );
}

export default Widget;