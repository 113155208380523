import React from 'react';

function Close() {
    return (
        <svg width="23" height="23" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.432 4.66068C25.9439 4.17252 25.1524 4.17252 24.6643 4.66068L15.5463 13.7787L6.42832
                4.66068C5.94017 4.17252 5.14871 4.17252 4.66056 4.66068C4.17239 5.14883 4.17239 5.94029 4.66056
                 6.42844L13.7785 15.5464L4.66058 24.6643C4.17242 25.1525 4.17242 25.9439 4.66058 26.4322C5.14873
                  26.9203 5.94019 26.9203 6.42834 26.4322L15.5463 17.3142L24.6643 26.4322C25.1524 26.9203 25.9439
                   26.9203 26.432 26.4322C26.9201 25.9439 26.9201 25.1525 26.432 24.6644L17.314 15.5464L26.432
                   6.42844C26.9201 5.94029 26.9201 5.14883 26.432 4.66068Z"
                fill="#000000"/>
        </svg>
    );
}

export default Close;
