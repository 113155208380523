import React from 'react';
import CalculateResult from "../CalculateResult.jsx";

function DeliveryTypeSteps({
                               showDelivery,
                               listCalculateResult,
                               dataSendToJson,
                               listTempModeVariant,
                               activeTariff,
                               setShowUserData,
                               showUserData,
                               showResult,
                               setActiveTariff,
                               setShowDelivery
                           }) {

    let disabledValue = !(showUserData && !showResult);
    return (
        (showDelivery && showDelivery && listCalculateResult.length !== 0) ?
            <>
                <div className="w-full flex flex-row">
                {
                    listCalculateResult.map((resultItem, index) => (
                        <CalculateResult resultItem={resultItem} key={index} dataSendToJson={dataSendToJson}
                                         listTempModeVariant={listTempModeVariant} activeTariff={activeTariff}
                                         setActiveTariff={setActiveTariff} showResult={showResult}
                                         setShowUserData={setShowUserData}/>
                    ))
                }
                </div>
                <div className="mt-5 w-full flex justify-end">
                    <div className="bg-primary py-2 px-5 rounded-sm text-white mr-4 cursor-pointer"
                         onClick={() => {
                             setShowDelivery(false)
                         }}>
                        Назад
                    </div>
                    <button className={" py-2 px-5 rounded-sm text-white normal-case font-normal cursor-pointer" +
                        (disabledValue ? " bg-primary/30" : " bg-primary")}
                            disabled={disabledValue}
                            onClick={() => {
                                setShowUserData(true)
                                setShowDelivery(false)
                            }}>
                        Оформить предзаявку
                    </button>
                </div>
            </>
            : false
    );
}

export default DeliveryTypeSteps;